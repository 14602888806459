<template>
  <v-dialog
    v-model="dialogEditarHistorico"
    @click:outside="$emit('update:dialogEditarHistorico', false)"
    @keydown.esc="$emit('update:dialogEditarHistorico', false)"
    width="500"
    scrollable
  >
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        Editar Tempo
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogEditarHistorico', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-6 py-8">
        <v-row>
          <!-- Data -->
          <v-col cols="12">
            <DataField label="Data" :data_sync.sync="data" obrigatorio />
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <TimeField
              :hora_sync.sync="hora_inicio"
              :label="'Hora Início:'"
              :entrada="entrada"
              :saida_intervalo="saida_intervalo"
              :volta_intervalo="volta_intervalo"
              :saida="saida"
              :disabled="!data"
            />
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <TimeField
              :hora_sync.sync="hora_fim"
              :label="'Hora Fim:'"
              :entrada="entrada"
              :saida_intervalo="saida_intervalo"
              :volta_intervalo="volta_intervalo"
              :saida="saida"
              :disabled="!data"
              :hora_start="hora_fim"
            />
          </v-col>

          <!-- Nota -->
          <v-col cols="12">
            <v-textarea v-model="note" label="Nota" dense rows="2"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn outlined @click="$emit('update:dialogEditarHistorico', false)">
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" :loading="loading" @click="editarTempo">
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { putAtividadeEditarTempo } from "@/api/colaborador/atividades_colaborador.js";

export default {
  name: "EditarHistoricoTempo",

  props: {
    dialogEditarHistorico: {
      type: Boolean,
    },
    tempo: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      data: null,
      hora_inicio: "00:00",
      hora_fim: "00:00",
      note: "",
      jornada_trabalho: null,
      dia_semana: null,
      entrada: "",
      saida_intervalo: "",
      volta_intervalo: "",
      saida: "",
    };
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    TimeField: () => import("@/components/fields/TimeField.vue"),
  },


  methods: {
    editarTempo() {
      this.loading = true;

      const tempo = {
        id: this.tempo.id,
        data: this.data,
        hora_inicio: this.hora_inicio,
        hora_fim: this.hora_fim,
        note: this.note,
      };

      putAtividadeEditarTempo(tempo.id, tempo)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.result == "success") {
              this.$emit("update:dialogEditarHistorico", false);
              this.$emit("fetch-historico");
            }
            if (response.data.result == "failed") {
              this.$toast.error(response.data.message);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    const { data, hora_inicio, hora_fim, note, jornada_trabalho } = this.tempo;


    console.log(this.tempo)

    this.jornada_trabalho = jornada_trabalho;
    this.data = data;
    this.note = note;
    this.hora_inicio = hora_inicio;
    this.hora_fim = hora_fim;

    if (this.data) {
      this.dia_semana = new Date(this.data)
        .toLocaleDateString("pt-BR", {
          weekday: "long",
        })
        .replace("-feira", "")
        .toUpperCase();
    }

    if (this.jornada_trabalho.length > 0) {
      let jornada = this.jornada_trabalho.find(
        (jornada) => jornada.dia_semana == this.dia_semana
      );
      if (jornada) {
        this.entrada = new Date(this.data + " " + jornada.entrada);
        this.saida_intervalo = new Date(
          this.data + " " + jornada.saida_intervalo
        );
        this.volta_intervalo = new Date(
          this.data + " " + jornada.volta_intervalo
        );
        this.saida = new Date(this.data + " " + jornada.saida);
      }
    }
  },
};
</script>

<style></style>
